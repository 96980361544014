import { Injectable } from '@angular/core';
import { CONFIG } from '@config/index';
import { AuthData } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private readonly storagePrefix: string;

  constructor() {
    this.storagePrefix = CONFIG.storagePrefix;
  }

  public set(name: string, elem: any): void {
    localStorage.setItem(`${this.storagePrefix}-${name}`, JSON.stringify(elem));
  }

  public get(name: string, defaultValue: any): any {
    const item = localStorage.getItem(`${this.storagePrefix}-${name}`);
    if (!item) {
      return defaultValue;
    }
    return JSON.parse(item);
  }

  public setAuthData(authData: AuthData): void {
    this.set('auth-data', authData);
  }

  public getAuthData(): AuthData | null {
    return this.get('auth-data', null);
  }

  public delete(name: string): void {
    localStorage.removeItem(`${this.storagePrefix}-${name}`);
  }

  public deleteAuthData(): void {
    this.delete('auth-data');
  }

  public deleteAll(): void {
    localStorage.clear();
  }

}
