import { BaseEntity } from '@shared/models';

export class Agency extends BaseEntity {
  public name: string;
  public contact: string;
  public phone: string;

  constructor(obj: Partial<Agency>) {
    super(obj);
    this.name = obj.name || '';
    this.contact = obj.contact || '';
    this.phone = obj.phone || '';
  }
}
