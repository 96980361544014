import { Pagination } from './pagination.interface';

export class PaginatedList<T> {
  records: T[];
  numberOfPageRecords: number;
  totalPages: number;
  totalRecords: number;

  constructor(obj: Partial<PaginatedList<T>>) {
    this.records = obj.records || [];
    this.numberOfPageRecords = obj.numberOfPageRecords || 0;
    this.totalPages = obj.totalPages || 0;
    this.totalRecords = obj.totalRecords || 0;
  }
}
