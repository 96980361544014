import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@shared/models';
import { AbstractCRUD } from '../abstract/crud.abstract';

@Injectable({
  providedIn: 'root',
})
export class UserService extends AbstractCRUD<User> {
  constructor(http: HttpClient) {
    super(http);
  }

  public get resourcePath(): string {
    return '/users';
  }
}
