import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuComponent } from './components/menu/menu.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { iconList } from './../shared/font-awesome-icons';

@NgModule({
  declarations: [PageNotFoundComponent, HeaderComponent, FooterComponent, MenuComponent],
  imports: [CommonModule, RouterModule, NgbModule, FontAwesomeModule],
  exports: [PageNotFoundComponent, HeaderComponent, FooterComponent, MenuComponent],
})
export class CoreModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(...iconList);
  }
}
