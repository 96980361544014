import { MenuItem } from './models';

export const MENU: MenuItem[] = [
  {
    code: 'ventas',
    name: 'Ventas',
    link: 'sales',
    permissionsRequired: [],
    submenu: [
      {
        code: 'tickets',
        name: 'Tickets',
        link: 'sales/tickets',
        permissionsRequired: [],
        submenu: null,
      },
    ],
  },
  {
    code: 'reports',
    name: 'Reportes',
    link: 'reports',
    permissionsRequired: [],
    submenu: [
      {
        code: 'cashier-balances',
        name: 'Cierre de Caja',
        link: 'reports/cashier-balances',
        permissionsRequired: [],
        submenu: null,
      },
      {
        code: 'tickets-sold',
        name: 'Boletos Vendidos',
        link: 'reports/tickets-sold',
        permissionsRequired: [],
        submenu: null,
      },
    ],
  },
  {
    code: 'admin',
    name: 'Administración',
    link: 'admin',
    permissionsRequired: [],
    submenu: [
      {
        code: 'users',
        name: 'Usuarios',
        link: 'admin/users',
        permissionsRequired: [],
        submenu: null,
      },
      {
        code: 'profiles',
        name: 'Perfiles',
        link: 'admin/profiles',
        permissionsRequired: [],
        submenu: null,
      },
      {
        code: 'agencies',
        name: 'Agencias',
        link: 'admin/agencies',
        permissionsRequired: [],
        submenu: null,
      },
      {
        code: 'locations',
        name: 'Lugares',
        link: 'admin/locations',
        permissionsRequired: [],
        submenu: null,
      },
      {
        code: 'routes',
        name: 'Rutas',
        link: 'admin/routes',
        permissionsRequired: [],
        submenu: null,
      },
      {
        code: 'busTypes',
        name: 'Tipos de Bus',
        link: 'admin/bus-types',
        permissionsRequired: [],
        submenu: null,
      },
      {
        code: 'priceTypes',
        name: 'Tipos de Precio',
        link: 'admin/price-types',
        permissionsRequired: [],
        submenu: null,
      },
    ],
  },
];
