import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class DateFormatterService extends NgbDateParserFormatter {
  constructor() {
    super();
  }

  private readonly DELIMITER = '/';

  public parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        month: parseInt(date[0], 10),
        day: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  public format(date: NgbDateStruct | null): string {
    return date
      ? String(date.day).padStart(2, '0') +
          this.DELIMITER +
          String(date.month).padStart(2, '0') +
          this.DELIMITER +
          String(date.year).padStart(2, '0')
      : '';
  }
}
