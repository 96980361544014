import { BaseEntity } from '@shared/models';
import { BusType } from './bus-type.model';

export class RouteRate extends BaseEntity {
  public billingRate: number;
  public busClass: BusType;

  constructor(obj: Partial<RouteRate>) {
    super(obj);
    this.billingRate = obj.billingRate || 0;
    this.busClass = obj.busClass || new BusType({});
  }
}
