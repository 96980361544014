import { BaseEntity } from '@shared/models';

export class Location extends BaseEntity {
  public name: string;

  constructor(obj: Partial<Location>) {
    super(obj);
    this.name = obj.name || '';
  }
}
