import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@shared/models';

@Component({
  selector: 'tig-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public user: User = new User({});

  constructor(
    public activeOffcanvas: NgbActiveOffcanvas,
    private readonly authService: AuthenticationService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.user = this.authService.getUserInfo();
  }

  public closeMenu() {
    this.activeOffcanvas.dismiss();
  }

  public logout() {
    this.activeOffcanvas.close();
    this.authService.logout();
  }

  public goTo(url: string): void {
    this.activeOffcanvas.close();
    this.router.navigateByUrl(url);
  }
}
